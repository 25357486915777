import styled from "styled-components";
import media from "styled-media-query";
import BackgroundImage from "gatsby-background-image";
import { Link } from "gatsby";

export const CaseMain = styled.main`
  padding: 2rem 20%;
  ${media.lessThan("large")`
    padding: 2rem var(--default-padding-mobile);
  `}
`;

export const CaseSumaryWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 3rem 1fr;
  ${media.lessThan("large")`
    grid-template-columns: 1fr;
  `}
`;
export const CaseSumarySection = styled.section``;
export const CaseSumary = styled.section``;
export const CaseSumaryTitle = styled.h1`
  font-size: var(--large-title-size);
  font-weight: bold;
`;
export const CaseSumarySubtitle = styled.h1`
  font-size: var(--medium-title-size);
  font-weight: bold;
`;
export const CaseSumaryDate = styled.label`
  font-size: var(--label-size);
`;
export const CaseTechnologies = styled.ul`
  list-style-type: none;
  display: flex;
  gap: 0.5rem;
  font-size: 0.7rem;
  width: 100%;
  padding: 0.5rem 0;
  flex-wrap: wrap;
  .keyword {
    &::before {
      content: "#";
    }
    color: var(--esx-red);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .technology {
    color: gray;
    font-size: 0.8rem;
    white-space: nowrap;
    border: 1px solid gray;
    padding: 0.2rem 1.3rem;
    border-radius: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const CaseSidebar = styled.aside`
  background: white;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 10px;
  padding: 2rem;
  ${media.lessThan("large")`
    margin-top: 1rem;
    padding: 1rem;
  `}
`;

export const CaseScenario = styled.section``;

export const CaseBody = styled.section`
  max-width: 70rem;
  padding: 1rem 0;
  ${media.lessThan("medium")`
    max-width: 100%;
  `}
  p,
  h1,
  h2,
  h3,
  h4,
  ul,
  ol,
  .tags,
  iframe,
  .button-post {
    font-size: 1rem;
    line-height: 1.7;
    word-break: break-word;
    ${media.lessThan("large")`
      word-break: break-word;
    `}
  }
  p {
    margin: 0 auto 1.6rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 2.4rem auto 1rem;
    color: var(--title-color);
  }
  ul,
  ol {
    list-style: disc;
    padding-left: 2.5rem;
    margin: 0 auto 1.6rem;
  }
  li {
    padding: 0.625rem 0;
    & > ul {
      margin-bottom: 0;
    }
  }
  p,
  li {
    code {
      word-wrap: break-word;
    }
  }
  img {
    display: block;
    max-width: 100%;
  }
  iframe {
    padding: 0 1.6rem 1.6rem;
    width: 100%;
    ${media.lessThan("large")`
      padding: 0 1rem;
    `}
  }
  blockquote {
    color: var(--postColor);
    border-left: 0.3rem solid var(--highlight);
    padding: 0 1.875rem;
    margin: 3.125rem auto;
  }
  hr {
    border: 1px solid var(--borders);
    margin: 3rem auto;
  }
  #twitter-widget-0,
  .instagram-media,
  .twitter-tweet {
    margin: 20px auto !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 800;
    letter-spacing: 0.069rem;
    line-height: 1.4;
  }
  h1 {
    font-size: 2.8rem;
    ${media.lessThan("large")`
      font-size: 1.875rem;
    `}
  }
  h2 {
    font-size: 2.1rem;
    ${media.lessThan("large")`
      font-size: 1.375rem;
    `}
  }
  h3 {
    font-size: 1.6rem;
    ${media.lessThan("large")`
      font-size: 1.125rem;
    `}
  }
  h4 {
    font-size: 1.4rem;
  }
  h5 {
    font-size: 1.2rem;
  }
  strong {
    font-weight: 700;
  }
  .gatsby-resp-image-background-image {
    z-index: 2;
    opacity: 0 !important;
  }
  .gatsby-resp-image-image {
    box-shadow: none !important;
    transition: opacity 0.2s;
    &.lazyload {
      opacity: 0;
    }
    &.lazyloaded {
      opacity: 1;
      z-index: 3;
    }
  }
  .gatsby-highlight {
    padding: 0 1.6rem 1.6rem;
    ${media.lessThan("large")`
      padding: 0;
    `}
  }
  .instagram-media {
    margin: 1rem auto !important;
  }
  a {
    border-bottom: 1px dashed var(--highlight);
    color: var(--highlight);
    text-decoration: none;
    transition: opacity 0.5s;
    svg {
      color: var(--postColor);
    }
    &:hover {
      opacity: 0.8;
    }
  }
  table {
    border-collapse: collapse;
    width: 100%;
    margin: 2rem 0;
  }

  td,
  th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--esx-red);
    color: white;
  }
  ${media.lessThan("medium")`
    .revert-on-mobile {
          .row {
            flex-direction: column-reverse;
          }
    }
  `}
`;
export const SectionLabel = styled.label`
  z-index: 3;
  width: auto;
  margin: 0 auto;
  color: #fafafa;
  background-color: var(--esx-red);
  padding: 0.5rem 2rem;
  border-radius: 50px;
`;

export const CaseActImageWrapper = styled.section`
  display: grid;
  grid-template-columns: 2fr 3rem 1fr;
  margin-top: -1.4rem;
  .vr-span {
    display: flex;
    flex-direction: column;
  }
  ${media.lessThan("large")`
    grid-template-columns: 1fr;
  `}
`;
export const ActImage = styled(BackgroundImage)`
  height: 25rem;
  width: 100%;
  border: 1px solid lightgrey;
  background-size: contain;
  ${media.lessThan("large")`
    height: 20rem;
  `}
`;

export const CaseProblemWrapper = styled.section`
  display: grid;
  grid-template-columns: 2fr 3rem 1fr;
  margin-top: -1.4rem;
  .vr-span {
    display: flex;
    flex-direction: column;
  }
  ${media.lessThan("large")`
    grid-template-columns: 1fr;
  `}
`;
export const CaseProblem = styled.section`
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 10px;
  padding: 3rem 2rem;
  height: auto;
  min-height: 20rem;
  ${media.lessThan("large")`
    padding: 2rem 1rem;
  `}
`;
export const CaseProblemImage = styled(BackgroundImage)`
  height: 100%;
  width: 100%;
  background-size: 10rem;
  ${media.lessThan("large")`
    height: 20rem;
  `}
`;

export const CaseSolutionWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 3rem 2fr;
  margin-top: -1.4rem;

  ${media.lessThan("large")`
   display: flex;
   flex-direction: column-reverse;
  `}
  .vr-span {
    display: flex;
    flex-direction: column;
  }
`;
export const CaseSolution = styled.section`
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 10px;
  padding: 3rem 2rem;
  height: auto;
  min-height: 20rem;

  ${media.lessThan("large")`
    padding: 2rem 1rem;
  `}
`;
export const CaseSolutionImage = styled(BackgroundImage)`
  height: 100%;
  width: 100%;
  background-size: 10rem;
  ${media.lessThan("large")`
    height: 20rem;
  `}
`;

export const CaseResultsWrapper = styled.section`
  margin-top: -1.4rem;
`;
export const CaseResults = styled.section`
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 10px;
  padding: 3rem 2rem;
  ${media.lessThan("large")`
    padding: 2rem 1rem;
  `}
`;

export const CaseHr = styled.hr`
  margin: auto 0;
  border-bottom: 2px dashed gray;
  ${media.lessThan("large")`
    display: none;
  `}
`;

export const CaseVr = styled.hr`
  margin: 0 auto;
  width: 2px;
  height: 4rem;
  border-left: 2px dashed gray;
  &.mobile-vr {
    display: none;
  }
  ${media.lessThan("large")`
    &.mobile-vr {
      display: inline;
    }
  `}
`;
export const SeeMoreWrapper = styled.div`
  text-align: center;
`;
export const CaseButton = styled.a`
  color: #fafafa;
  background: var(--esx-red);
  font-size: var(--label-size);
  border: 1px solid var(--esx-red);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  text-transform: uppercase;
  &:hover {
    color: #fafafa;
  }
`;
