import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import * as S from "../components/Case/styled.js";
import HeroSection from "../components/HeroSection";

import { graphql } from "gatsby";
import ShareButtons from "../components/ShareIcons";

const CasePage = ({ data, pageContext }) => {
  console.log(data);
  const caseData = data.caseContent;
  const url = `https://www.esx.com.br${pageContext.slug}`;

  const esxTheme = {
    main: "#fafafa",
    color: "#fafafa",
    gradient: "linear-gradient(var(--esx-red), var(--esx-red))",
    titleColor: "#fff",
    textColor: "#fafafa",
    boldTextColor: "#fff",
  };

  return (
    <Layout>
      <SEO
        title={caseData.frontmatter.title}
        image={caseData.frontmatter.image}
        description="Conheça os cases da ESX"
      />
      <HeroSection
        theme={esxTheme}
        label={`${caseData.frontmatter.date} • ${caseData.timeToRead} min de leitura`}
        title={`<span style="font-size: 3rem">${caseData.frontmatter.title}</span>`}
        tags={caseData.frontmatter.technologies}
        fixedImage={caseData.frontmatter.image.childImageSharp.fluid}
      />
      <S.CaseMain>
        <S.CaseSumaryTitle>{caseData.frontmatter.title}</S.CaseSumaryTitle>
        <ShareButtons title={caseData.frontmatter.title} url={url} />
        <S.CaseBody>
          <div dangerouslySetInnerHTML={{ __html: caseData.html }}></div>
        </S.CaseBody>
        <S.SeeMoreWrapper>
          <S.CaseButton href={caseData.frontmatter.pdf.publicURL}>
            Veja a solução deste Case
          </S.CaseButton>
        </S.SeeMoreWrapper>
      </S.CaseMain>
    </Layout>
  );
};

export const query = graphql`
  query SpecificCase($slug: String!) {
    caseContent: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        color
        technologies
        pdf {
          publicURL
        }
        date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
        image {
          childImageSharp {
            fluid(
              maxWidth: 1000
              maxHeight: 600
              fit: COVER
              background: "#fafafa"
            ) {
              ...GatsbyImageSharpFluid_withWebp
              src
            }
            fixed(width: 600, height: 300) {
              src
            }
          }
        }
      }
    }
  }
`;

export default CasePage;
