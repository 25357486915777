import React from "react";

import {
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import * as S from "./styled";

const ShareButtons = ({ title, url }) => {
  return (
    <S.ShareContainer>
      <LinkedinShareButton url={url} title="Compartilhar no LinkedIn">
        <LinkedinIcon
          size={40}
          round={false}
          iconFillColor="gray"
          bgStyle={{ fill: "transparent" }}
        />
      </LinkedinShareButton>

      <EmailShareButton
        subject={title}
        body={`Leia sobre: ${title} em `}
        title="Compartilhar por Email"
        url={url}
      >
        <EmailIcon
          size={40}
          round={false}
          iconFillColor="gray"
          bgStyle={{ fill: "transparent" }}
        />
      </EmailShareButton>

      <FacebookShareButton url={url} title="Compartilhar no Facebook">
        <FacebookIcon
          size={40}
          round={false}
          iconFillColor="gray"
          bgStyle={{ fill: "transparent" }}
        />
      </FacebookShareButton>

      <WhatsappShareButton
        url={url}
        title={title}
        title="Compartilhar no WhatsApp"
      >
        <WhatsappIcon
          size={40}
          round={false}
          iconFillColor="gray"
          bgStyle={{ fill: "transparent" }}
        />
      </WhatsappShareButton>
    </S.ShareContainer>
  );
};
export default ShareButtons;
